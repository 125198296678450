import { CSSProperties, PropsWithChildren } from "react";

export enum CardVariant {
  DEFAULT = "DEFAULT",
  HIGHLIGHT = "HIGHLIGHT",
  PRIMARY = "PRIMARY",
}

export type CardProps = PropsWithChildren<{
  variant?: CardVariant;
  action?: () => void;
  href?: string;
  dense?: boolean;
  isLoading?: boolean;
  empty?: boolean;
  download?: boolean;
  style?: CSSProperties;
}>;
