import { VacationHours } from "../../../types/VacationHours";
import { defaultResponseTransform, emptySplitApi } from "..";
import { VacationHourList } from "../../../components/templates/VacationHours/VacationHours.types";

const currentYear = new Date().getFullYear();

export const vacationHoursApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getVacationHours: builder.query<VacationHourList, { year: number }>({
      query: ({ year = currentYear }) => {
        return { url: `vacation-hours?year=${year}` };
      },
      transformResponse: (response, _meta, { year = currentYear }) => {
        const defaultTransform = defaultResponseTransform(response);
        return new VacationHourList({
          items: defaultTransform,
          year,
        });
      },
      providesTags: (result) => {
        const entry = result?.items?.[0];
        if (entry) {
          return [
            {
              type: "RequestedVacationHours",
              id: new Date(entry.date).getFullYear(),
            },
          ];
        }
        return ["RequestedVacationHours"];
      },
    }),
    getAvailableVacationHours: builder.query<VacationHours, void>({
      query: () => "vacation-hours/available",
      transformResponse: defaultResponseTransform,
      providesTags: ["AvailableVacationHours"],
    }),
  }),
});

export const { useGetVacationHoursQuery, useGetAvailableVacationHoursQuery } =
  vacationHoursApiSlice;
