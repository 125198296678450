import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

type BannerOrchestratorState = [string[], Dispatch<SetStateAction<string[]>>];

const Context = createContext<BannerOrchestratorState | undefined>(undefined);

interface BannerOrchestratorProps {
  children: ReactNode;
}

export default function BannerOrchestrator({
  children,
}: BannerOrchestratorProps) {
  return (
    <Context.Provider value={useState<string[]>([])}>
      {children}
    </Context.Provider>
  );
}

export function useBanner() {
  const bannersContext = useContext(Context);

  if (!(bannersContext instanceof Array)) {
    throw Error("Cannot use useBanner() outside of BannerOrchestrator tree");
  }

  const [banners, setBanners] = bannersContext;

  const id = useRef(Date.now().toString());

  const bannerIsVisible = banners[0] === id.current;

  return useMemo(() => {
    function showBanner() {
      setBanners((prev) => [...prev, id.current]);
    }

    function hideBanner() {
      setBanners((prev) => prev.filter((bannerId) => bannerId !== id.current));
    }

    return {
      showBanner,
      hideBanner,
      bannerIsVisible,
    };
  }, [bannerIsVisible, setBanners]);
}
