import { createContext, ReactNode, useContext } from "react";
import { ThemeProvider as ThemeProviderStyledComponents } from "styled-components";
import { Theme } from "../config/themes";
import { useLabel } from "./useLabel";

export function ThemeProvider({
  children,
  theme,
}: {
  children: ReactNode;
  theme?: any;
}) {
  let {
    label: { theme: currentTheme },
  } = useLabel();

  // Get theme from rest props (storybook context)
  if (theme) {
    currentTheme = theme;
  }

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
      }}
    >
      <ThemeProviderStyledComponents theme={currentTheme}>
        {children}
      </ThemeProviderStyledComponents>
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme can only be used within ThemeProvider");
  }

  return context;
}

interface ThemeContextInterface {
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextInterface | undefined>(
  undefined
);
