import { IconSize } from "./IconSize";

export function toPx(size?: IconSize | string | null) {
  switch (size) {
    case IconSize.XS:
      return "18px";
    case IconSize.SM:
      return "24px";
    case IconSize.MD:
      return "32px";
    case IconSize.LG:
      return "48px";
    case IconSize.XL:
      return "64px";
    case IconSize.XXL:
      return "76px";
    default:
      throw new Error(`Invalid size parameter`);
  }
}
