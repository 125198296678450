import { Label } from "../../../types/Label";
import { theme } from "../../themes/gorillas";

import logo from "./assets/logo.png";
import logoFull from "./assets/logo-full.png";
import appIcon from "./assets/appicon.png";
import appleTouchIcon from "./assets/apple-touch-icon.png";
import backdropTop from "./assets/backdrop/top.svg";
import backdropLeft from "./assets/backdrop/left.svg";
import backdropRight from "./assets/backdrop/right.svg";

export const Gorillas: Label = {
  name: "gorillas",
  theme,
  assets: {
    logo,
    logoFull,
    appIcon,
    appleTouchIcon,
    backdrop: {
      top: backdropTop,
      left: backdropLeft,
      right: backdropRight,
    },
  },
  contact: {
    phoneNumber: "+31682343610",
    email: "helpdesk@payrollselect.nl",
  },
  gtm: process.env.NEXT_PUBLIC_GTM_ID_GORILLAS as string,
};
