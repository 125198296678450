import { Context, createWrapper } from "next-redux-wrapper";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { rootReducer } from "./reducers";
import { emptySplitApi } from "./apis";

export const makeStore = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ serializableCheck: false }).concat([
        sagaMiddleware,
        emptySplitApi.middleware,
      ]);
    },
    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

const wrapper = createWrapper(makeStore);

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore["getState"]>;
export type RootDispatch = RootStore["dispatch"];
export default wrapper;
