import { defineMessages } from "react-intl";

/**
 * Update this with validations you're using. This'll make sure your error messages
 * and those of Yup's are translated.
 *
 * So, if you were to use Yup.string().<whatevs> and there's no key for it yet,
 * add it here.
 */
const validationMessages = {
  base: defineMessages({
    required: {
      id: "validation.base.required",
      defaultMessage: "Veld is verplicht",
    },
    min: {
      id: "validation.base.min",
      defaultMessage: "Veld dient minimaal {min} tekens te zijn",
    },
    max: {
      id: "validation.base.max",
      defaultMessage: "Veld dient maximaal {max} tekens te zijn",
    },
    number: {
      id: "validation.base.number",
      defaultMessage: "Veld dient een getal te zijn",
    },
    integer: {
      id: "validation.base.integer",
      defaultMessage: "Veld dient een heel getal te zijn",
    },
    email: {
      id: "validation.base.email",
      defaultMessage: "Ongeldige e-mail",
    },
    awsPassword: {
      id: "validation.base.awsPassword",
      defaultMessage:
        "Je wachtwoord moet minimaal 8 tekens zijn; bestaan uit kleine letters, hoofdletters en cijfers; een speciaal teken bevatten en mag geen spaties bevatten",
    },
  }),
  date: defineMessages({
    min: {
      id: "validation.date.min",
      defaultMessage: "Datum moet minimaal {min} zijn",
    },
    max: {
      id: "validation.date.max",
      defaultMessage: "Datum mag maximaal {max} zijn",
    },
  }),
  number: defineMessages({
    min: {
      id: "validation.number.min",
      defaultMessage: "Nummer moet minimaal {min} of hoger zijn",
    },
    max: {
      id: "validation.number.max",
      defaultMessage: "Nummer moet {max} of lager zijn",
    },
    phoneNumber: {
      id: "validation.number.phone",
      defaultMessage: "Ongeldig telefoonnummer",
    },
    money: {
      id: "validation.number.money",
      defaultMessage: "Veld dient een geldig geld bedrag te zijn",
    },
    wageTooHigh: {
      id: "validation.number.wageTooHigh",
      defaultMessage: "Uurloon te hoog",
    },
    step: {
      id: "validation.number.step",
      defaultMessage: "Veld dient een veelvoud van {step} te zijn",
    },
  }),
};

export default validationMessages;
