import { put, takeEvery } from "redux-saga/effects";
import fetch from "../../services/fetch";
import isLoggedIn from "../../services/isLoggedIn";
import {
  User,
  UserMetaResponse,
  UserMetaReportBackResponse,
} from "../reducers/userMeta/userMeta.types";
import { keysToCamelCase } from "../../services/convert";

function* fetchUserMeta() {
  try {
    const _isLoggedIn: boolean = yield isLoggedIn();

    if (!_isLoggedIn) {
      return;
    }

    const response: Response = yield fetch("profile");
    const profile: UserMetaResponse = yield response
      .json()
      .then((res) => keysToCamelCase(res))
      .then((res) => {
        res.user = new User(res.user);
        return res;
      });
    const { user, financial, address } = profile;

    yield put({
      type: "userMeta/fetchSuccess",
      payload: {
        user,
        financial,
        address,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put({
      type: "userMeta/fetchError",
      payload: {
        error,
      },
    });
  }
}

function* reportBack() {
  const formData = new FormData();

  // We report back starting today, no actual date is selected in the UI.
  const today = new Date().toISOString().split("T")[0];
  formData.append("since", today);

  try {
    const uri = "/report-back";
    const json: UserMetaReportBackResponse = yield fetch(uri, {
      body: formData,
      method: "POST",
    });

    yield put({
      type: "userMeta/updateUser",
      payload: {
        exit: false,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put({
      type: "userMeta/reportBackError",
      payload: {
        error,
      },
    });
  }
}

function* loginSuccess() {
  yield put({
    type: "userMeta/fetch",
  });
}

export function* userMetaSaga() {
  yield takeEvery("userMeta/fetch", fetchUserMeta);
  yield takeEvery("userMeta/reportBack", reportBack);
  yield takeEvery("auth/loginSuccess", loginSuccess);
  yield fetchUserMeta();
}
