import React from "react";
import { Svg } from "../../atoms/Svg/Svg";
import { toPx } from "./toPx";
import { IconSize } from "./IconSize";
import { IconName } from "./IconName";
import styled from "styled-components";

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName;
  size: IconSize;
}

export const IconDefaultProps = {
  size: IconSize.MD,
  ...Svg.defaultProps,
};

export function Icon({ size, width, height, name, ...props }: IconProps) {
  const _width = width ? width : toPx(size);
  const _height = height ? height : toPx(size);
  const _name = `icon/${name}`;
  return <Svg name={_name} width={_width} height={_height} {...props} />;
}

export const _Icon = styled(Icon)``;

Icon.defaultProps = IconDefaultProps;
