import { Auth } from "@aws-amplify/auth";

export default async function addAuthTo(headers: Headers) {
  try {
    const auth = await Auth.currentAuthenticatedUser();

    const authUserKey = `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_WEB_CLIENT_ID}.LastAuthUser`;
    const authUser = auth.storage[authUserKey];

    const tokenKey = `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_WEB_CLIENT_ID}.${authUser}.idToken`;
    const token = auth.storage[tokenKey];

    headers.append(`Authorization`, `Bearer ${token}`);
  } catch {}
}
