import { Labels } from "../../../types/Label";

export interface UserType {
  /** @example null */
  exit: boolean;
  /** @example null */
  isForgotten: boolean;
  /** @example 0 */
  id: number;
  /** @example "A." */
  initials: string;
  /** @example "Bert" */
  givenName: string;
  /** @example "Mertz" */
  familyName: string;
  /** @example "2016-05-23" */
  dateOfBirth: Date | null;
  /** @example "dorris.mertz@example.com" */
  email: string;
  /** @example null */
  phoneNumber: unknown;
  /** @example "en_EN" */
  locale: string;
  /** @example "PAYROLL" */
  perspective: string;
  /** @example "Bert Mertz" */
  fullName: string;
  /** @example "https://ui-avatars.com/api/?name=Bert+Mertz&color=7F9CF5&background=EBF4FF&size=250" */
  profilePhotoUrl: string;
  /** @example "EMPLOYEE" */
  role: "EMPLOYEE" | "HELPDESK";
  /** @example true */
  impersonatingEmployee: boolean;
}

export interface Financial {
  /** @example "" */
  iban: string;
  /** @example "FOUR_WEEK" */
  paymentFrequency: string;
  applyWageTaxDiscount: boolean;
  updateRequested: boolean;
}

export interface Address {
  /** @example "Heerlen" */
  city: string;
  /** @example "NL" */
  countryCode: string;
  /** @example "1234 AA" */
  postalCode: string;
  /** @example "Henri Jonasstraat" */
  streetName: string;
  /** @example "24" */
  streetNumber: string;
  /** @example "A" */
  streetSuffix: string;
}
export interface UserMetaResponse {
  user: User;
  financial: Financial;
  address: Address;
}

export interface UserMetaReportBackResponse {
  success: boolean;
}

export interface UserMeta {
  user?: User;
  isReadOnly: boolean;
  financial?: Financial;
  address?: Address;
  isLoading: boolean;
  error?: Error;
}

export interface Impersonator {
  givenName: string;
  nameAdditive: string;
  familyName: string;
}

export class User implements UserType {
  externalId!: string;
  givenName!: string;
  nameAdditive!: string;
  phoneNumber!: string;
  familyName!: string;
  email!: string;
  dateOfBirth!: Date | null;
  profilePhotoUrl!: string;
  exit!: boolean;
  fullName!: string;
  id!: number;
  impersonatingEmployee!: boolean;
  initials!: string;
  isForgotten!: boolean;
  locale!: string;
  perspective!: string;
  brand?: Labels;
  impersonation?: Impersonator;
  role!: "EMPLOYEE" | "HELPDESK";

  constructor(obj: object) {
    // Convert the keys of the object to camelCase.
    Object.assign(this, obj);

    if (this.dateOfBirth) {
      this.dateOfBirth = new Date(this.dateOfBirth);
    }

    if (this.profilePhotoUrl == null) {
      this.profilePhotoUrl = `https://ui-avatars.com/api/?name=${this.givenName}+${this.familyName}&color=7F9CF5&background=EBF4FF&size=250&w=96&q=75`;
    }
  }
}
