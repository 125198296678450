import { defaultResponseTransform, emptySplitApi } from "..";
import { FinancialRestrictions } from "../../../types/FinancialRestrictions";

export const financialApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFinancialRestrictions: builder.query<FinancialRestrictions, void>({
      query: () => "financial-restriction",
      transformResponse: defaultResponseTransform,
      providesTags: ["FinancialRestrictions"],
    }),
  }),
});

export const { useGetFinancialRestrictionsQuery } = financialApiSlice;
