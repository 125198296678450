import { useEffect } from "react";

export default function useServiceWorkerEvents() {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox;
      // add event listeners to handle any of PWA lifecycle event
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
      wb.addEventListener("installed", (event) => {
        // eslint-disable-next-line no-console
        console.log(`Event ${event.type} is triggered.`);
        // eslint-disable-next-line no-console
        console.log(event);
      });

      wb.addEventListener("controlling", (event) => {
        // eslint-disable-next-line no-console
        console.log(`Event ${event.type} is triggered.`);
        // eslint-disable-next-line no-console
        console.log(event);
      });

      wb.addEventListener("activated", (event) => {
        // eslint-disable-next-line no-console
        console.log(`Event ${event.type} is triggered.`);
        // eslint-disable-next-line no-console
        console.log(event);
      });

      wb.register();
    }
  }, []);
}

export function useListenForAppUpdates(onUpdateAvailable) {
  useEffect(() => {
    const wb = getWb();

    if (!wb) return;

    // eslint-disable-next-line no-console
    console.log("Running...");

    wb.addEventListener("waiting", onUpdateAvailable);

    wb.register();
  }, [onUpdateAvailable]);

  const updateApp = () => {
    const wb = getWb();

    wb.addEventListener("controlling", (event) => {
      window.location.reload();
    });

    // Send a message to the waiting service worker, instructing it to activate.
    wb.messageSkipWaiting();
  };

  return updateApp;
}

function getWb() {
  return typeof window !== "undefined" &&
    "serviceWorker" in navigator &&
    window.workbox !== undefined
    ? window.workbox
    : undefined;
}
