import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SickLeave } from "./index";

const initialState: SickLeave = {
  isLoading: true,
  error: undefined,
};

export const sickLeaveSlice = createSlice({
  name: "sickLeave",
  initialState,
  reducers: {
    fetch: (state) => {
      return { ...state, isLoading: true };
    },
    fetchSuccess: (state, action: PayloadAction<Array<Date>>) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: undefined,
      };
    },
    fetchError: (state, { payload: { error } }) => {
      return { ...state, isLoading: false, error };
    },
  },
});
