import PropTypes, { InferProps } from "prop-types";
import styled, { css } from "styled-components";

export type _SvgContainerProps = InferProps<typeof _SvgContainerPropTypes>;

export const _SvgContainerPropTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export const _SvgContainerDefaultProps = {
  fill: "currentColor",
};

export const _SvgContainer = styled.div<_SvgContainerProps>`
  display: inline-grid;
  justify-items: center;
  align-items: center;

  svg {
    display: block;
    ${({ fill }) =>
      fill &&
      css`
        fill: ${fill};
      `}
    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}
    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
  }

  &[fill] {
    svg {
      ${({ fill }) =>
        fill &&
        css`
          fill: ${fill};
        `}
    }
  }
`;

_SvgContainer.displayName = "_SvgContainer";
_SvgContainer.propTypes = _SvgContainerPropTypes;
_SvgContainer.defaultProps = _SvgContainerDefaultProps;
