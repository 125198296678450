import * as Yup from "yup";
import validationMessages from "../config/validationMessages";
import axiosClient from "../config/axiosClient";
import axios, { Canceler } from "axios";

export function registerCustomTranslationMessages() {
  const yupLocale = validationErrorMessagesToYupMessages();

  Yup.setLocale(yupLocale);
}

export function registerCustomYupValidationRules() {
  addPhoneNumberValidation();
  addMoneyValidation();
  addStepsValidation();
  addAWSPasswordPolicySchemeValidation();
}

function addMoneyValidation() {
  Yup.addMethod(Yup.number, "money", function () {
    return this.test(
      "money",
      { key: "money", errorGroup: "number" },
      function (value) {
        if (!value) return true; // required() can take care of that

        // Commented out for now, you can uncomment this if money shouldn't allow values over 99.
        // if(value > 99) {
        //   return false;
        // }

        return RegExp(/^[0-9]*[.,]?[0-9]{0,2}$/).test(value.toString());
      }
    );
  });
}

function addStepsValidation() {
  Yup.addMethod(Yup.number, "step", function (step: number) {
    return this.test(
      "step",
      { key: "step", errorGroup: "number", values: { step } },
      function (value) {
        if (!value) return true; // required() can take care of that
        const valid =
          typeof value !== "number" ||
          value % step === 0 ||
          Number((value * 100).toFixed(0)) % Number((step * 100).toFixed(0)) ===
            0;
        return valid;
      }
    );
  });
}

function addPhoneNumberValidation() {
  Yup.addMethod(Yup.string, "phoneNumber", function () {
    let debounce: any;

    // Cache the response so if the user enters the same phoneNumber, we don't need to call the API again, we already
    // know if it's valid or not. Added benefit is that because Formik by default validates the entire form whenever
    // it validates, we don't keep calling the API as this value might not have changed.
    const cache: {
      [key: string]: boolean;
    } = {};

    // Store cancellation function we get from Axios. This allows us to cancel the currently running API call
    // (if there is one) when we run a new API call. This solves a racing condition that would happen if call 1 is really
    // slow and we fire call 2 which resolves faster than call 1. A user would get a validation error even though the
    // current value might be valid.
    let cancelRequest: Canceler | undefined;

    // Pass {key: <some key from src/config/validationMessages.ts>} as the message.
    // You can also pass values: {key: <someKey>, values: {<yourValues>}}, this is
    // useful if you add arguments to your custom validation rules like how max()
    // accepts a number for how much is too much.
    return this.test(
      "phoneNumber",
      { key: "phoneNumber", errorGroup: "number" },
      function (value) {
        clearTimeout(debounce);

        if (typeof cancelRequest === "function") {
          cancelRequest();
        }

        return new Promise((resolve) => {
          if (!value) return resolve(true); // The required() rule can take care of that

          debounce = setTimeout(() => {
            const cachedValue = cache[value];

            if (cachedValue !== undefined) {
              return resolve(cachedValue);
            }

            axiosClient
              .get("/validate-phone-number", {
                params: {
                  phoneNumber: value,
                },
                cancelToken: new axios.CancelToken((cancelToken) => {
                  cancelRequest = cancelToken;
                }),
              })
              .then(() => {
                resolve(true);
                cache[value] = true; // Cache the resolved value
              })
              .catch((error) => {
                // Axios throws an error if we cancel the request. We can use this check so we don't tell the user
                // their input is wrong when it's just a cancelled request.
                if (axios.isCancel(error)) {
                  return;
                }

                resolve(false);
                cache[value] = false; // Cache the resolved value
              });
          }, 500);
        });
      }
    );
  });
}

function addAWSPasswordPolicySchemeValidation() {
  Yup.addMethod(Yup.string, "awsPassword", function () {
    return this.test(
      "awsPassword",
      { key: "awsPassword", errorGroup: "base" },
      function (value = "") {
        return RegExp(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\-“!@#%&\/,><’:;|_~`])\S{8,99}$/
        ).test(value.toString());
      }
    );
  });
}

function validationErrorMessagesToYupMessages() {
  let errors: { [key: string]: { [subKey: string]: Function } } = {};

  let sharedErrors: { [key: string]: Function } = {};

  for (const errorName of Object.keys(validationMessages.base)) {
    sharedErrors[errorName] = (values: {}) => ({
      key: errorName,
      values,
      errorGroup: "base",
    });
  }

  // Remove base messages as they will be merged with all different field types
  const specificValidationMessages: Omit<typeof validationMessages, "base"> & {
    base?: typeof validationMessages.base;
  } = { ...validationMessages };
  delete specificValidationMessages.base;

  errors.string = { ...sharedErrors };
  errors.number = { ...sharedErrors };
  errors.mixed = { ...sharedErrors };
  errors.date = { ...sharedErrors };

  for (const errorGroup of Object.keys(specificValidationMessages)) {
    if (
      !validationMessages.hasOwnProperty(
        errorGroup as keyof typeof validationMessages
      )
    ) {
      continue;
    }

    for (const errorName of Object.keys(
      validationMessages[errorGroup as keyof typeof validationMessages]
    )) {
      errors[errorGroup][errorName] = (values: {}) => {
        return {
          key: errorName,
          values,
          errorGroup: errorGroup,
        };
      };
    }
  }

  return errors;
}

export type YupValidationError =
  | string
  | undefined
  | {
      key: keyof (typeof validationMessages)[keyof typeof validationMessages];
      errorGroup: keyof typeof validationMessages;
      values: {};
    };
