import { createSlice } from "@reduxjs/toolkit";
import { UserMeta } from "./userMeta.types";

const initialState: UserMeta = {
  isLoading: true,
  isReadOnly: false,
};

export const userMetaSlice = createSlice({
  name: "userMeta",
  initialState,
  reducers: {
    updateUser: (state, { payload: user }) => {
      return {
        ...state,
        user: state.user
          ? {
              // overwrite isReadOnly if user isForgotten, otherwise keep env setting
              isReadOnly: user?.isForgotten ? true : undefined,
              ...state.user,
              ...user,
            }
          : undefined,
      };
    },
    fetch: (state) => {
      return { ...state, isLoading: true };
    },
    fetchSuccess: (state, { payload: { user, financial, address } }) => {
      return { ...state, user, financial, address, isLoading: false };
    },
    fetchError: (state, { payload: { error } }) => {
      return { ...state, error, isLoading: false };
    },
    reportBackError: (state, { payload: { error } }) => {
      return { ...state, error, loading: false };
    },
  },
});
