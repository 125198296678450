import { FC, useMemo } from "react";
import { Icon } from "../../molecules/Icon/Icon";
import { HeadsupPropType, HeadsupType } from "./Headsup.types";
import {
  _Headsup,
  _HeadsupClose,
  _HeadsupContent,
  _HeadsupIcon,
} from "./_Headsup";
import { IconSize } from "../../molecules/Icon/IconSize";

const Headsup: FC<HeadsupPropType> = ({
  children,
  onClose,
  variant = HeadsupType.DEFAULT,
  "data-testid": dataTestid = "headsup",
}) => {
  const icon = useMemo(() => {
    switch (variant) {
      case HeadsupType.SUCCESS:
        return "CheckMark";
      case HeadsupType.DEFAULT:
      default:
        return "Warning";
    }
  }, [variant]);

  return (
    <_Headsup variant={variant} data-variant={variant} data-testid={dataTestid}>
      <_HeadsupIcon>
        <Icon name={icon} size={IconSize.SM} fill="currentColor" />
      </_HeadsupIcon>
      <_HeadsupContent>{children}</_HeadsupContent>
      <_HeadsupClose onClick={onClose}>
        <Icon name="Cross" size={IconSize.SM} fill="currentColor" />
      </_HeadsupClose>
    </_Headsup>
  );
};

export default Headsup;
