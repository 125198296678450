import { Payslip } from "../../../types/Payslip";
import { defaultResponseTransform, emptySplitApi } from "..";

export const payslipsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMostRecentPayslips: builder.query<Payslip, void>({
      query: () => "payslips/most-recent",
      transformResponse: defaultResponseTransform,
      providesTags: ["Payslips"],
    }),
  }),
});

export const { useGetMostRecentPayslipsQuery } = payslipsApiSlice;
