import { createContext, FC, ReactElement, useContext } from "react";
import Headsup from "./Headsup";
import useHeadsupController from "./Headsup.hook";
import { headsupItemType } from "./Headsup.types";

export type HeadsupContextType = {
  add: ReturnType<typeof useHeadsupController>["add"];
  headsups: headsupItemType[];
};

export const HeadsupContext = createContext({} as HeadsupContextType);

export function useHeadsup() {
  const context = useContext(HeadsupContext);

  if (!context) {
    throw new Error("useHeadsup must be used within a HeadsupProvider");
  }

  return context;
}

export const HeadsupProvider: FC = (props): ReactElement => {
  const { headsups, add } = useHeadsupController();

  return (
    <>
      <HeadsupContext.Provider value={{ headsups, add }} {...props} />
      {headsups.map((headsup) => {
        return (
          <Headsup
            key={headsup.key}
            onClose={headsup.remove}
            variant={headsup.config.type}
          >
            {headsup.children}
          </Headsup>
        );
      })}
    </>
  );
};
