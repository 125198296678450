import { HoursWorked } from "../../../types/HoursWorked";
import { defaultResponseTransform, emptySplitApi } from "..";

export const declarationsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMostRecentDeclarations: builder.query<HoursWorked, void>({
      query: () => "declarations/most-recent",
      transformResponse: defaultResponseTransform,
      providesTags: ["Declarations"],
    }),
  }),
});

export const { useGetMostRecentDeclarationsQuery } = declarationsApiSlice;
