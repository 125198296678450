import { ComponentType, useState } from "react";
import { nanoid } from "nanoid";
import { noop } from "../../../services/noop";

export type ModalConfigType = {
  onClose: (...args: any) => any;
};

export interface modalItemType<P = any> {
  Component: ComponentType<P>;
  props: P;
  key: string;
  config: ModalConfigType;
}

export function useModalController() {
  const [modals, setModals] = useState<modalItemType[]>([]);

  function add<P extends object>(
    Component: ComponentType<P>,
    props?: P,
    config?: Partial<ModalConfigType>
  ) {
    const key = nanoid();
    const defaultConfig = {
      onClose: noop,
    };

    const newModal: modalItemType = {
      Component,
      props: {
        ...props,
        onRequestClose: (response?: any) => {
          _remove(key, response);
        },
      },
      key,
      config: { ...defaultConfig, ...config },
    };

    setModals((_modals: modalItemType[]) => {
      return [..._modals, newModal];
    });

    return {
      close: () => {
        _remove(newModal.key);
      },
    };
  }

  function _remove(key: string, response?: any) {
    setModals((_modals) => {
      const newModals = [..._modals];

      const removeModalIndex = newModals.findIndex((modalItem) => {
        return modalItem.key === key;
      });

      if (removeModalIndex === -1) {
        // eslint-disable-next-line no-console
        console.warn("Modal is already removed");
        return newModals;
      }

      const modalToRemove = _modals[removeModalIndex];

      modalToRemove.config.onClose(response);

      newModals.splice(removeModalIndex, 1);

      return newModals;
    });
  }

  return {
    modals,
    add,
  };
}

export default useModalController;
