import { Theme } from "../config/themes";

export enum Labels {
  Gorillas = "gorillas",
  Payroll = "payrollselect",
  Picnic = "picnic",
  RPOStaffing = "rpostaffing",
}

export type LabelContactInfo = {
  phoneNumber: string;
  email: string;
};

export interface Label {
  /**
   * Name of the label
   */
  name: `${Labels}`;

  /**
   * Style configuration for styled-components
   */
  theme: Theme;

  assets: {
    /**
     * Small logo used in top right of app
     */
    logo: { src: string; width: number; height: number };

    /**
     * Larger version of logo used in top right op app when logging in
     */
    logoFull: { src: string; width: number; height: number };

    /**
     * Logo used on home screen/favicon
     */
    appIcon: { src: string; width: number; height: number };

    /**
     * Logo used on home screen
     */
    appleTouchIcon: { src: string; width: number; height: number };

    backdrop?: {
      top: { src: string; width: number; height: number };
      left: { src: string; width: number; height: number };
      right: { src: string; width: number; height: number };
    };
  };

  /**
   * Contact information
   */
  contact: LabelContactInfo;

  /**
   * Google Tag Manager ID
   */
  gtm: string;
}
