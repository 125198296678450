const getCognitoToken = (response: any) => {
  if (!response?.storage) return null;

  const authUserKey = `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_WEB_CLIENT_ID}.LastAuthUser`;
  const authUser = response.storage[authUserKey];

  const tokenKey = `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_WEB_CLIENT_ID}.${authUser}.idToken`;
  const token = response.storage[tokenKey];

  return token;
};

export default getCognitoToken;
