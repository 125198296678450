export function formatHours(time: number = 0) {
  const negative = time < 0;
  const _time = Math.abs(time);
  const hours = Math.floor(_time);
  const minutes = Math.round((_time - hours) * 60);

  return {
    hours: negative ? -hours : hours,
    minutes: minutes,
  };
}

export function formatHoursToFixed(time: number): string {
  return time.toFixed(2);
}

export function formatHoursToString(time: number): string {
  const formattedHours = formatHours(time);

  const negative = formattedHours.hours < 0;

  const _hours = Math.abs(formattedHours.hours).toString().padStart(2, "0");
  const _minutes = formattedHours.minutes.toString().padStart(2, "0");

  return `${negative ? "-" : ""}${_hours}:${_minutes}`;
}

export default formatHoursToString;
