import { useState } from "react";
import { nanoid } from "nanoid";
import { noop } from "../../../services/noop";
import {
  headsupConfigType,
  headsupItemType,
  HeadsupType,
} from "./Headsup.types";

export function useHeadsupController() {
  const [headsups, setHeadsups] = useState<headsupItemType[]>([]);

  function add(children: any, config?: Partial<headsupConfigType>) {
    const key = nanoid();
    const defaultConfig = {
      onClose: noop,
      type: HeadsupType.DEFAULT,
    };

    let timeout = null;

    if (!config || config.timeout === undefined) {
      timeout = setTimeout(() => {
        _remove(newModal.key);
      }, config?.timeout ?? 10000);
    }

    const newModal: headsupItemType = {
      children,
      key,
      config: { ...defaultConfig, ...config },
      timeout,
      remove: () => {
        _remove(newModal.key);
      },
    };

    setHeadsups((_headsups: headsupItemType[]) => {
      return [..._headsups, newModal];
    });

    return {
      close: () => {
        _remove(newModal.key);
      },
    };
  }

  function _remove(key: string, response?: any) {
    setHeadsups((_headsups) => {
      const newHeadsups = [..._headsups];

      const removeHeadsupIndex = newHeadsups.findIndex((headsupItem) => {
        return headsupItem.key === key;
      });

      if (removeHeadsupIndex === -1) {
        // eslint-disable-next-line no-console
        console.warn("Headsup is already removed");
        return newHeadsups;
      }

      const headsupToRemove = _headsups[removeHeadsupIndex];

      clearTimeout(headsupToRemove.timeout);
      headsupToRemove.config.onClose(response);

      newHeadsups.splice(removeHeadsupIndex, 1);

      return newHeadsups;
    });
  }

  return {
    headsups,
    add,
  };
}

export default useHeadsupController;
