import { Auth, CognitoUser } from "@aws-amplify/auth";
import { noop } from "../noop";

export async function isLoggedIn() {
  const currentAuthenticatedUser: CognitoUser =
    await Auth.currentAuthenticatedUser().catch(noop);

  if (!currentAuthenticatedUser) {
    return false;
  }

  return true;
}
