import { ReactNode } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { useLanguage } from "../../hooks/useLanguage";
import { Language } from "../../types/Language";
import Text from "../atoms/Text";

const messages = {
  [Language.EN]: require("../../lang/compiled/en.json"),
  [Language.NL]: require("../../lang/compiled/nl.json"),
} as const;

export default function IntlProvider({ children }: { children: ReactNode }) {
  const { language } = useLanguage();

  return (
    <ReactIntlProvider
      wrapRichTextChunksInFragment={true}
      locale={language}
      defaultLocale={Language.NL}
      messages={messages[language]}
      textComponent={Text}
    >
      {children}
    </ReactIntlProvider>
  );
}
