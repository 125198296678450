import { AxiosRequestConfig } from "axios";
import { SWRConfiguration } from "swr";
import Bugsnag from "@bugsnag/js";
import { Auth } from "@aws-amplify/auth";
import axiosClient, { config as axiosClientConfigBase } from "./axiosClient";
import getCognitoToken from "../services/getCognitoToken/getCognitoToken";
import { keysToCamelCase } from "../services/convert";

export async function fetcher(url: string) {
  const auth = await Auth.currentAuthenticatedUser();

  const token = getCognitoToken(auth);

  const config: AxiosRequestConfig = {
    ...axiosClientConfigBase,
    headers: { Authorization: `Bearer ${token}` },
  };

  const res = await axiosClient.get(url, config);

  return keysToCamelCase(res.data);
}

/**
 * Key is used to group the error in Bugsnag. If the url is not dynamic, you can just pass they key useSWR gives you.
 * If the URL is dynamic, pass a custom key.
 * @param error
 * @param key
 */
export function reportApiErrorToBugsnag(error: any, key: string) {
  Bugsnag.notify(error, (event) => {
    const status = error.response?.status;

    event.groupingHash = `${key}-${status}`;
    event.context = key;
  });
}

export function dontReportApiErrorToBugsnag(error: any, key: string) {
  // This method doesn't do anything but passing `undefined` is not allowed and creating an empty method every time is boring.
  // This empty method allows us to make reporting to bugsnag an opt-out feature instead of opt-in so we can't forget.
}

const swrConfig: SWRConfiguration = {
  fetcher,
  onError: reportApiErrorToBugsnag,
};

export default swrConfig;
