import {
  camelCase,
  cloneDeep,
  isArray,
  isPlainObject,
  map,
  mapKeys,
  mapValues,
  snakeCase,
} from "lodash";

// Converts object keys to camelCase.
export const toCamelCase = (obj: object) => {
  return mapKeys(obj, (value, key) => camelCase(key));
};

// Converts object keys to snake_case.
export const toSnakeCase = (obj: any) => {
  return mapKeys(obj, (value, key) => snakeCase(key));
};

export const keysToCamelCase: any = (obj: any) => {
  if (isArray(obj)) return obj;

  let camelCaseObject = cloneDeep(obj);

  camelCaseObject = mapKeys(camelCaseObject, (value, key) => {
    return camelCase(key);
  });

  // Recursively apply throughout object
  return mapValues(camelCaseObject, (value: object) => {
    if (isPlainObject(value)) {
      return keysToCamelCase(value);
    } else if (isArray(value)) {
      return map(value, keysToCamelCase);
    } else {
      return value;
    }
  });
};

export const keysToSnakeCase: any = (obj: any) => {
  if (isArray(obj)) return obj;

  let snakeCaseObject = cloneDeep(obj);

  snakeCaseObject = mapKeys(snakeCaseObject, (value, key) => {
    return snakeCase(key);
  });

  // Recursively apply throughout object
  return mapValues(snakeCaseObject, (value: object) => {
    if (isPlainObject(value)) {
      return keysToSnakeCase(value);
    } else if (isArray(value)) {
      return map(value, keysToSnakeCase);
    } else {
      return value;
    }
  });
};
