import { IconName } from "../../../components/molecules/Icon/IconName";

export enum HeadsupType {
  DEFAULT = "default",
  SUCCESS = "Success",
}

export type headsupConfigType = {
  onClose: (...args: any) => any;
  timeout?: number;
  type?: HeadsupType;
};

export interface headsupItemType {
  children: any;
  key: string;
  timeout: any;
  config: headsupConfigType;
  remove: (...args: any) => any;
}

export type HeadsupPropType = {
  onClose: () => void;
  variant?: HeadsupType;
  icon?: IconName;
  "data-testid"?: string;
};
