import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Label, Labels } from "../types/Label";
import { PayRoll, Gorillas, Picnic, RPOStaffing } from "../config/labels/";
import { LABEL } from "../constants/label";
import { getLabelNameFromHost } from "../helpers/get-label-name-from-host";

function getLabelConfig(labelId?: string) {
  switch (labelId) {
    case LABEL.RPO_STAFFING:
    case Labels.RPOStaffing:
      return RPOStaffing;
    case LABEL.GORILLAS:
    case Labels.Gorillas:
      return Gorillas;
    case LABEL.PICNIC:
    case Labels.Picnic:
      return Picnic;
    case LABEL.PAYROLL_SELECT:
    case Labels.Payroll:
    default:
      return PayRoll;
  }
}

interface LabelContextInterface {
  label: Label;
  labelName: string | undefined;
  setLabelName: Dispatch<SetStateAction<string | undefined>>;
}

const LabelContext = createContext<LabelContextInterface | null>(null);

interface LabelProviderProps extends PropsWithChildren<{}> {
  initialLabel?: string;
}

export function LabelProvider({
  children,
  initialLabel = getLabelNameFromHost(getHost()),
}: LabelProviderProps) {
  const [labelName, setLabelName] = useState<string | undefined>(initialLabel);
  const labelConfig = useMemo(() => getLabelConfig(labelName), [labelName]);

  return (
    <LabelContext.Provider
      value={{
        label: labelConfig,
        labelName,
        setLabelName,
      }}
    >
      {children}
    </LabelContext.Provider>
  );
}

export function useLabel() {
  const context = useContext(LabelContext);

  if (!context) {
    throw new Error("useLabel can only be used within LabelProvider");
  }

  return context;
}

function getHost() {
  if (typeof window !== "undefined") return window.location.host;
}
