import { Label } from "../../../types/Label";
import { theme } from "../../themes/rpostaffing";
import logo from "./assets/logo.png";
import logoFull from "./assets/logo-full.png";
import appIcon from "./assets/appicon.png";
import appleTouchIcon from "./assets/apple-touch-icon.png";
import backdropTop from "./assets/backdrop/top.svg";
import backdropLeft from "./assets/backdrop/left.svg";
import backdropRight from "./assets/backdrop/right.svg";

export const RPOStaffing: Label = {
  name: "rpostaffing",
  theme,
  assets: {
    appIcon,
    appleTouchIcon,
    logo: logo,
    logoFull: logoFull,
    backdrop: {
      top: backdropTop,
      left: backdropLeft,
      right: backdropRight,
    },
  },
  contact: {
    phoneNumber: "+31682088640",
    email: "helpdesk@rpostaffing.nl",
  },
  gtm: process.env.NEXT_PUBLIC_GTM_ID_RPO_STAFFING as string,
};
