import styled, { css } from "styled-components";
import { HeadsupType } from "./Headsup.types";

export const _Headsup = styled.div<{ variant: HeadsupType }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: ${(props) => props.theme.colors.ERROR}e8;
  padding: 10px;
  display: flex;
  color: #fff;
  align-items: flex-start;

  @media all and (display-mode: standalone) {
    padding-top: calc(10px + env(safe-area-inset-top));
  }

  ${({ variant }) => {
    switch (variant) {
      case HeadsupType.SUCCESS:
        return css`
          background-color: ${({ theme }) => theme.colors.SUCCESS_MESSAGE}e8;
        `;
      case HeadsupType.DEFAULT:
      default:
        return css`
          background-color: ${(props) => props.theme.colors.ERROR}e8;
        `;
    }
  }}
`;

export const _HeadsupIcon = styled.div`
  margin-right: 10px;
`;

export const _HeadsupClose = styled.button`
  display: block;
  background: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  color: inherit;
  margin-left: 10px;
`;

export const _HeadsupContent = styled.div`
  font-weight: 500;
  flex: 1 1 100%;
`;
