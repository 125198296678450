import type { AppProps } from "next/app";
import { LanguageProvider } from "../hooks/useLanguage";
import IntlProvider from "../components/providers/IntlProvider";
import React, { ReactNode, StrictMode } from "react";
import useServiceWorkerEvents from "../hooks/useServiceWorkerEvents";
import BannerOrchestrator from "../contexts/BannerOrchestrator";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { SWRConfig } from "swr";
import styled, { StyleSheetManager } from "styled-components";
import swrConfig from "../config/swr";
import {
  registerCustomTranslationMessages,
  registerCustomYupValidationRules,
} from "../services/validationService";
import "/public/global.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Amplify } from "@aws-amplify/core";
import awsConfig from "../../aws-config";
import { AuthProvider } from "../contexts/Auth/Auth";
import NextNProgress from "nextjs-progressbar";
import { ModalProvider } from "../components/atoms/Modal/modal.provider";
import wrapper from "../redux";
import { HeadsupProvider } from "../components/atoms/Headsup/Headsup.provider";
import { ThemeProvider, useTheme } from "../hooks/useTheme";
import { LabelProvider, useLabel } from "../hooks/useLabel";
import { HeadLabel } from "../components/atoms/head";
import { MaintenanceMode } from "../components/atoms/MaintenanceMode";
import { CookiesProvider } from "react-cookie";
import isPropValid from "@emotion/is-prop-valid";

Amplify.configure(awsConfig);

let ErrorBoundary: any = styled.div``;

try {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_STAGE as string,
  });

  ErrorBoundary =
    Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? styled.div``;
} catch (e) {
  //
}

registerCustomYupValidationRules();
registerCustomTranslationMessages();

// Wrap NextNProgress in wrapper so we can use ThemeProvider
const ProgressWrapper = () => {
  const { theme } = useTheme();
  return (
    <NextNProgress
      height={4}
      color={theme.colors.PRIMARY}
      showOnShallow={false}
      options={{ showSpinner: false }}
    />
  );
};

const ThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
  const {
    label: { theme },
  } = useLabel();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function MyApp({ Component, pageProps }: AppProps) {
  useServiceWorkerEvents();

  return (
    <ErrorBoundary>
      <CookiesProvider>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <SWRConfig value={swrConfig}>
            <AuthProvider>
              <LabelProvider>
                <ThemeProviderWrapper>
                  <StrictMode>
                    <LanguageProvider>
                      <IntlProvider>
                        <HeadsupProvider>
                          <ModalProvider>
                            <BannerOrchestrator>
                              <MaintenanceMode />
                              <ProgressWrapper />
                              <HeadLabel />
                              <Component {...pageProps} />
                            </BannerOrchestrator>
                          </ModalProvider>
                        </HeadsupProvider>
                      </IntlProvider>
                    </LanguageProvider>
                  </StrictMode>
                </ThemeProviderWrapper>
              </LabelProvider>
            </AuthProvider>
          </SWRConfig>
        </StyleSheetManager>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

// This implements the default behavior from styled-components v5
export const shouldForwardProp = (propName: any, target: any) => {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

export default wrapper.withRedux(MyApp);
