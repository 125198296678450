import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { cache, fetchJson } from "../../services/fetch/fetch";
import { FetchSickLeaveResponse } from "../reducers/sickLeave";

function* fetchSickLeave(action: PayloadAction<{ cache: boolean }>) {
  try {
    const uri = "/sick-leave";
    const json: FetchSickLeaveResponse = yield fetchJson(uri, {
      useCache: true,
      cacheKey: "sickLeave/fetch",
    });

    yield put({
      type: "sickLeave/fetchSuccess",
      payload: json.map((date: string) => Date.parse(date)),
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    yield put({
      type: "sickLeave/fetchError",
      payload: {
        error,
      },
    });
  }
}

function* purgeSickLeave() {
  cache?.del("sickLeave/fetch");
}

export function* sickLeaveSaga() {
  yield takeEvery("sickLeave/fetch", fetchSickLeave);
  yield takeEvery("sickLeave/purge", purgeSickLeave);
}
