import styled, { css } from "styled-components";

export enum TextVariant {
  LINK = "link",
  ERROR = "error",
}

interface TextProps {
  variant?: TextVariant;
  fontSize?: string;
  textAlign?: TextAlign;
}

const Text = styled.span<TextProps>`
  font-family: ${(props) =>
    props.variant
      ? props.theme.text.variants[props.variant].fontFamily
      : props.theme.text.fontFamily};
  ${(props) => css`
    text-align: ${props.textAlign ?? "left"};
  `};
`;

export default Text;

export enum TextAlign {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}
