export default {
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_PROJECT_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint:
    "https://ig4gywcicbambdxqyfzoulekoi.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: process.env.NEXT_PUBLIC_COGNITO_PROJECT_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};
