import React, { FC, LegacyRef, useEffect, useRef } from "react";
import PropTypes, { InferProps } from "prop-types";
import { _SvgContainer } from "./_SvgContainer";
import { _SvgError } from "./_SvgError";
import { _SvgLoading } from "./_SvgLoading";
import { ReactSvg } from "./ReactSvg";

export type SvgProps = InferProps<typeof SvgPropTypes>;

export const SvgPropTypes = {
  name: PropTypes.string.isRequired,
  forceLoadingState: PropTypes.bool,
  ..._SvgContainer.propTypes,
};

export const SvgDefaultProps = {
  forceLoadingState: false,
  ..._SvgContainer.defaultProps,
};

export const Svg: FC<SvgProps> = ({
  name,
  fill,
  width,
  height,
  forceLoadingState,
  ...props
}) => {
  const reactSvgRef = useRef<any>();
  const src = name ? `/svg/${name}.svg` : "";
  const className = `svg-${name}`;
  const fallback = () => <_SvgError>Error</_SvgError>;
  const loading = () => <_SvgLoading>⋯</_SvgLoading>;
  const reactSvgProps = { src, className, fallback, loading };

  // Engage an override to allow component to remain in loading state
  // This is purely for Storybook/testing purposes, since React SVG doesn't
  // expose this API.
  useEffect(() => {
    if (forceLoadingState === true) reactSvgRef.current?.forceLoadingState();
  }, [forceLoadingState]);

  return (
    <_SvgContainer
      fill={fill}
      width={width}
      height={height}
      data-test-id="styled-svg"
      {...props}
    >
      <ReactSvg ref={reactSvgRef as LegacyRef<any>} {...reactSvgProps} />
    </_SvgContainer>
  );
};

Svg.propTypes = SvgPropTypes;
Svg.defaultProps = SvgDefaultProps;
