import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { ChallengeName } from "amazon-cognito-identity-js";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import getCognitoToken from "../../services/getCognitoToken/getCognitoToken";
import { User } from "../../redux/reducers/userMeta/userMeta.types";
import { fetcher } from "../../config/swr";

export interface MwpCognitoUser extends CognitoUser {
  challengeName?: ChallengeName;
  attributes: {
    email: string;
    given_name: string;
  };
}

export enum Role {
  UNAUTHENTICATED = "Unauthenticated",
  EMPLOYEE = "Employee",
  IMPERSONATING_EMPLOYEE = "ImpersonatingEmployee",
  HELPDESK = "Helpdesk",
}

type signInProps = {
  username: string;
  password: string;
};

type AuthContextProps = {
  Auth: AuthClass;
  isLoading: boolean;
  token: string | null;
  user: MwpCognitoUser | null;
  profile: User | null;
  role: Role;
  knownUser: string | undefined;
  signIn: (options: signInProps) => Promise<MwpCognitoUser | null>;
  signOut: () => Promise<any>;
  setKnownUser: (user: AuthContextProps["knownUser"]) => any;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export const AuthProvider: FC = (props): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<MwpCognitoUser | null>(null);
  const [profile, setProfile] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [knownUser, setKnownUser] = useState<AuthContextProps["knownUser"]>();

  const fetchUser = useCallback(async () => {
    setIsLoading(true);

    const user = await Auth.currentAuthenticatedUser().catch(() => {});

    setUser(user ?? null);

    if (user) {
      const res = await fetcher("/profile");
      setProfile(res.user);
    }
    const token = getCognitoToken(user);

    setToken(token);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const signOut = async () => {
    const response = await Auth.signOut().catch((err) => {
      throw err;
    });

    // force reload window to prevent Redux / useSWR caching
    window.location.href = "/";

    return response;
  };

  const signIn = async ({ username, password }: signInProps) => {
    setKnownUser(username);

    const response = await Auth.signIn({
      username,
      password,
    }).catch((err) => {
      throw err;
    });

    setUser(response);

    const res = await fetcher("/profile");
    setProfile(res.user);

    fetchUser();

    return response;
  };

  // Determine the current role of the user, by default Unauthenticated.
  let currentRole: Role = Role.UNAUTHENTICATED;
  const isAuthenticated = !isLoading && profile && user;

  if (isAuthenticated) {
    const { role, impersonatingEmployee } = profile;
    const isEmployee = role === "EMPLOYEE";
    const isHelpdesk = role === "HELPDESK" && !impersonatingEmployee;
    const isImpersonatingEmployee =
      role === "HELPDESK" && impersonatingEmployee;

    if (isEmployee) {
      currentRole = Role.EMPLOYEE;
    } else if (isHelpdesk) {
      currentRole = Role.HELPDESK;
    } else if (isImpersonatingEmployee) {
      currentRole = Role.IMPERSONATING_EMPLOYEE;
    }
  }

  return (
    <AuthContext.Provider
      {...props}
      value={{
        Auth,
        isLoading: isLoading,
        token,
        user,
        profile,
        role: currentRole,
        knownUser,
        signIn,
        signOut,
        setKnownUser,
      }}
    />
  );
};
