export enum IconSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export type IconSizes = `${IconSize}`;

export const iconSizes = Object.values(IconSize).filter(
  (v) => typeof v === "string"
);
