import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { keysToCamelCase } from "../../services/convert";
import { baseUrl } from "../../services/fetch/baseUrl";
import addJsonTo from "../../services/fetch/add/json";
import addAuthTo from "../../services/fetch/add/auth";

export const defaultResponseTransform = (response: any) => {
  if (response.status === 401 || response.status === 403) {
    window.location.href = "/inloggen";
  } else {
    return keysToCamelCase(response.data);
  }
};

export const emptySplitApi = createApi({
  tagTypes: [
    "Declarations",
    "AvailableVacationHours",
    "RequestedVacationHours",
    "Payslips",
    "Payments",
    "Absence",
    "FinancialRestrictions",
    "Documents",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      addJsonTo(headers);
      await addAuthTo(headers);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
