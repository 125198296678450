import storageAvailable from "storage-available";
import { LANGUAGE_LOCAL_STORAGE_KEY } from "../../config/storage";
import { Language } from "../../types/Language";

export default function setLanguageToStorage(language: Language) {
  if (typeof window === "undefined") {
    return;
  }

  if (!storageAvailable("localStorage")) {
    // eslint-disable-next-line no-console
    console.warn(`localStorage is not available for writing`);
    return;
  }

  localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language);
}
