import nl from "date-fns/locale/nl";
import { format, getISOWeek, getISOWeeksInYear } from "date-fns";
import { formatHoursToFixed } from "../../../services/formatHours/formatHours";

export type VacationHoursResponseItem = {
  id: string;
  hours: number | null;
  date: string;
  pending?: boolean;
  editable?: boolean;
};

export type VacationHoursResponse = {
  data: VacationHoursResponseItem[];
};

export type useVacationHourPropTypes = {
  year: number;
};

export type VacationHourWeekItem = {
  index: number;
  name: string;
  items: VacationHourItem[];
  hasPendingHours: boolean;
};

export class VacationHourList {
  items!: VacationHourItem[];
  groupedByWeeks: VacationHourWeekItem[];

  constructor({
    items,
    year,
  }: {
    items: VacationHoursResponseItem[];
    year: number;
  }) {
    this.items = items.map((item) => new VacationHourItem(item)).reverse();
    const weeks = getISOWeeksInYear(new Date(year, 1, 1));

    this.groupedByWeeks = [...new Array(weeks)].map((_, index) => {
      const weekNumber = "Week " + (index + 1);

      return {
        index,
        name: weekNumber,
        items: [],
        hasPendingHours: false,
      };
    });

    this.items.forEach((item) => {
      const weekIndex = getISOWeek(item.date);

      this.groupedByWeeks[weekIndex - 1].items.push(item);
    });

    // Sort the days within a week in ascending order
    this.groupedByWeeks.forEach((week) => {
      // Add indicator if there is a pending request in this week
      week.hasPendingHours = week.items.some((item) => item.pending);

      // Sort days in ascending order
      return week.items.sort(
        (day1, day2) => day1.date.getTime() - day2.date.getTime()
      );
    });

    this.groupedByWeeks = this.groupedByWeeks
      // Filter out all empty weeks
      .filter((week) => week.items.length > 0)
      // Reverse the list so that the months are in descending order
      .reverse();
  }
}

export class VacationHourItem {
  id!: string;
  hours!: number;
  date!: Date;
  dayName!: string;
  formattedHours!: string;
  formattedDate!: string;
  pending!: boolean;
  editable!: boolean;

  constructor({
    id,
    hours,
    date,
    pending = false,
    editable = false,
  }: VacationHoursResponseItem) {
    if (hours) {
      this.hours = hours;
      this.formattedHours = formatHoursToFixed(hours);
    }

    this.id = id;
    this.pending = pending;
    this.editable = editable;
    this.date = new Date(date);
    this.formattedDate = format(this.date, "dd-MM-yyyy");
    this.dayName = format(this.date, "eeee", { locale: nl });
  }
}
