import { transparentize } from "polished";
import { TextVariant } from "../../components/atoms/Text";
import { CardVariant } from "../../components/atoms/Card/Card.types";
import { ButtonVariant } from "../../components/atoms/Button/ButtonVariants";
import { NotificationVariant } from "../../components/atoms/Notification/Notification.types";

// Don't import this directly unless you're writing stories. If you need access to Color, use theme.colors. Reason for
// this is that you can't dynamically update the Color enum and also re-render the app with the new theme.
// (Yes, you can update enums but the app won't re-render.) if you were to build a dark mode for example, it wouldn't
// work if you were directly accessing the enum, it will work when accessing it from the theme. Just replace the theme
// and off you go 🚀️
// Storybook controls don't have access to the theme and using this enum forces everyone to use the same colors without
// Cluttering our styled components with random color strings.
export enum Color {
  PRIMARY = "#FF2C2C",
  PRIMARY_HOVER = "#FF2C2C",
  ACCENT = "#FF2C2C",
  TEXT = "#2A2A2A",
  TEXT_LIGHT = "#5c5c5c",
  LINK = "#FF2C2C",
  ERROR = "#DE423C",
  NOTIFICATION = "#FF0844",
  SUCCESS_MESSAGE = "#00c33e",
  WHITE = "#FFF",
  BLACK = "#000",
  TRANSPARENT = "transparent",
  INHERIT = "inherit",
  GREY = "rgb(153, 153, 153)",
  LIGHT_GREY = "rgb(240, 240, 240)",
  BORDER = "#8A8A8A",
  DOCK = "#000",
  MENU = "#000",
  AVATAR = "rgb(108,108,108) linear-gradient(45deg, rgba(108,108,108,1) 0%, rgba(208,208,208,1) 100%)",
  SKELETON_BASE = "rgba(0,0,0,0.045)",
  SKELETON_HIGHLIGHT = "rgba(0,0,0,0.065)",
}

export enum FontFamily {
  PRIMARY = "Isidora Sans Webfont, sans-serif",
  TITLE = "Isidora Sans Webfont, sans-serif",
}

export const UIConfig = {
  DOCK_HEIGHT: 61,
  TOP_BAR_HEIGHT: 70,
};

// If this theme object becomes unwieldy, feel free to split it up.
export const theme = {
  name: "Gorillas",
  colors: Color,
  fontFamily: FontFamily,
  shadows: [
    // Different levels of shadow. Higher you go, bigger the shadow. Allows us to create consistent shadows across elements.
    "0 2px 5px 4px rgba(0, 0, 0, 0.04)",
    "0 3px 8px 2px rgba(0, 0, 0, 0.12)",
    "0 2px 5px 4px rgba(0, 0, 0, 0.15)",
  ],
  text: {
    color: Color.TEXT,
    fontFamily: FontFamily.PRIMARY,
    variants: {
      [TextVariant.LINK]: {
        color: Color.LINK,
        fontFamily: FontFamily.PRIMARY,
      },
      [TextVariant.ERROR]: {
        color: Color.ERROR,
        fontFamily: FontFamily.PRIMARY,
      },
    },
  },
  button: {
    variants: {
      [ButtonVariant.WHITE]: {
        backgroundColor: Color.WHITE,
        textColor: Color.PRIMARY,
        hover: {
          textColor: Color.BLACK,
          backgroundColor: Color.WHITE,
        },
      },
      [ButtonVariant.DEFAULT]: {
        backgroundColor: Color.PRIMARY,
        textColor: Color.WHITE,
        hover: {
          textColor: Color.WHITE,
          backgroundColor: Color.PRIMARY_HOVER,
        },
      },
      [ButtonVariant.TRANSPARENT]: {
        backgroundColor: transparentize(0.85, Color.WHITE),
        textColor: Color.PRIMARY,
        hover: {
          textColor: Color.WHITE,
          backgroundColor: Color.PRIMARY,
        },
      },
      [ButtonVariant.LINK]: {
        backgroundColor: Color.TRANSPARENT,
        textColor: Color.LINK,
        hover: {
          textColor: Color.LINK,
          backgroundColor: Color.TRANSPARENT,
        },
      },
    },
  },
  tabBar: {
    tab: {
      textColor: Color.BLACK,
      activeTextColor: Color.WHITE,
      activeBackgroundColor: Color.PRIMARY,
    },
  },
  card: {
    variants: {
      [CardVariant.DEFAULT]: {
        backgroundColor: transparentize(0.55, Color.WHITE),
        textColor: Color.TEXT,
        textColorLight: Color.TEXT_LIGHT,
        iconColor: Color.PRIMARY,
      },
      [CardVariant.HIGHLIGHT]: {
        backgroundColor: Color.WHITE,
        textColor: Color.TEXT,
        textColorLight: Color.TEXT_LIGHT,
        iconColor: Color.PRIMARY,
      },
      [CardVariant.PRIMARY]: {
        backgroundColor: Color.PRIMARY,
        textColor: Color.WHITE,
        textColorLight: Color.WHITE,
        iconColor: Color.WHITE,
      },
    },
  },
  Notification: {
    Variants: {
      [NotificationVariant.PRIMARY]: {
        backgroundColor: Color.PRIMARY,
        textColor: Color.WHITE,
        iconColor: Color.WHITE,
      },
      [NotificationVariant.ERROR]: {
        backgroundColor: Color.ERROR,
        textColor: Color.WHITE,
        iconColor: Color.WHITE,
      },
    },
  },
  overlay: {
    wrapper: {
      backgroundColor: transparentize(0.6, Color.BLACK),
    },
    innerWrapper: {
      backgroundColor: Color.WHITE,
    },
  },
  progressBar: {
    point: {
      color: Color.GREY,
      markerBorderColor: Color.TRANSPARENT,
      variants: {
        active: {
          color: Color.PRIMARY,
          markerBorderColor: Color.PRIMARY,
        },
      },
    },
  },
  banner: {
    backgroundColor: Color.WHITE,
  },
  layout: {
    scrollArea: {
      offsetTop: UIConfig.TOP_BAR_HEIGHT,
      offsetBottom: UIConfig.DOCK_HEIGHT,
    },
  },
  backdrop: {
    gradient: {
      start: "#e9e9e9",
      end: "#d6d6d6",
    },
  },
};
