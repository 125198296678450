import { createContext, FC, ReactElement, useContext } from "react";
import useModalController, { modalItemType } from "./modal.hook";
import FocusTrap from "focus-trap-react";

export type ModalContext = {
  add: ReturnType<typeof useModalController>["add"];
  modals: modalItemType[];
};

export const ModalContext = createContext({} as ModalContext);

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
}

export const ModalProvider: FC = (props): ReactElement => {
  const { modals, add } = useModalController();

  return (
    <>
      <ModalContext.Provider value={{ modals, add }} {...props} />
      {modals.map((modalItem) => {
        return (
          <modalItem.Component
            key={modalItem.key}
            {...modalItem.props}
          ></modalItem.Component>
        );
      })}
    </>
  );
};
