import { PropsWithChildren } from "react";

export enum NotificationVariant {
  PRIMARY = "PRIMARY",
  ERROR = "ERROR",
}

export type NotificationProps = PropsWithChildren<{
  variant?: NotificationVariant;
  onClick?: () => void;
  href?: string;
  dense?: boolean;
}>;
