const PAYROLL_SELECT = "PAYROLL_SELECT" as const;
const RPO_STAFFING = "RPO_STAFFING" as const;
const GORILLAS = "GORILLAS" as const;
const PICNIC = "PICNIC" as const;

const LABEL = {
  PAYROLL_SELECT,
  RPO_STAFFING,
  GORILLAS,
  PICNIC,
} as const;

const HOST_LABEL = {
  "employee.rpostaffing.nl": LABEL.RPO_STAFFING,
  "test.employee.rpostaffing.nl": LABEL.RPO_STAFFING,
  "acc.employee.rpostaffing.nl": LABEL.RPO_STAFFING,
  "scm.employee.rpostaffing.nl": LABEL.RPO_STAFFING,

  "picnic.employee.payrollselect.nl": LABEL.PICNIC,
  "picnic.test.employee.payrollselect.nl": LABEL.PICNIC,
  "picnic.acc.employee.payrollselect.nl": LABEL.PICNIC,
  "picnic.scm.employee.payrollselect.nl": LABEL.PICNIC,

  "employee.payrollselect.nl": LABEL.PAYROLL_SELECT,
  "test.employee.payrollselect.nl": LABEL.PAYROLL_SELECT,
  "acc.employee.payrollselect.nl": LABEL.PAYROLL_SELECT,
  "scm.employee.payrollselect.nl": LABEL.PAYROLL_SELECT,

  "gorillas.employee.payrollselect.nl": LABEL.GORILLAS,
  "gorillas.test.employee.payrollselect.nl": LABEL.GORILLAS,
  "gorillas.acc.employee.payrollselect.nl": LABEL.GORILLAS,
  "gorillas.scm.employee.payrollselect.nl": LABEL.GORILLAS,
} as const;

// For development environments we'll allow some alternative domain names to map
// to specific themes
if (process.env.NODE_ENV === "development") {
  Object.assign(HOST_LABEL, {
    "localhost:3000": LABEL.PAYROLL_SELECT,
    "payrollselect.localhost:3000": LABEL.PAYROLL_SELECT,
    "rpostaffing.localhost:3000": LABEL.RPO_STAFFING,
    "gorillas.localhost:3000": LABEL.GORILLAS,
    "picnic.localhost:3000": LABEL.PICNIC,
  });
}

export { LABEL, HOST_LABEL };
