import { UpcomingSalary } from "../../../types/UpcomingSalary";
import { defaultResponseTransform, emptySplitApi } from "..";

export const paymentsApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingPayments: builder.query<UpcomingSalary, void>({
      query: () => "payments/upcoming",
      transformResponse: defaultResponseTransform,
      providesTags: ["Payments"],
    }),
  }),
});

export const { useGetUpcomingPaymentsQuery } = paymentsApiSlice;
