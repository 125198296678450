import { Props as ReactSvgProps, ReactSVG } from "react-svg";

export class ReactSvg extends ReactSVG {
  constructor(props: ReactSvgProps) {
    super(props);
  }

  forceLoadingState() {
    this.setState({ ...this.state, isLoading: true });
  }
}
