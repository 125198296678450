import { useRouter } from "next/router";
import React, { useCallback, useEffect } from "react";
import fromUnixTime from "date-fns/fromUnixTime";
import isBefore from "date-fns/isBefore";
export const MaintenanceMode = () => {
  const router = useRouter();

  const envSetting = process.env.NEXT_PUBLIC_MAINTENANCE_WINDOW_END;
  const endOfMaintenanceWindow = envSetting ? parseInt(envSetting) : null;
  const endOfMaintenanceWindowOverrideKey = "endOfMaintenanceWindowOverride";

  const isInMaintenanceMode = useCallback((): boolean => {
    // Check if we have a localStorage flag to overwrite any other setting
    if (localStorage.getItem(endOfMaintenanceWindowOverrideKey)) {
      return false;
    }

    if (endOfMaintenanceWindow) {
      // Compare current time to timestamp in env
      const currentTime = new Date();
      const endTime = fromUnixTime(endOfMaintenanceWindow);
      if (isBefore(currentTime, endTime)) {
        return true;
      }
    }

    // Default
    return false;
  }, [endOfMaintenanceWindow]);

  useEffect(() => {
    // In maintenance mode, redirect to maintenance page.
    if (router.pathname !== "/maintenance" && isInMaintenanceMode()) {
      router.push("/maintenance");
    }

    // No longer in maintenance mode, redirect to home.
    if (router.pathname === "/maintenance" && !isInMaintenanceMode()) {
      router.push("/");
    }
  }, [router, isInMaintenanceMode]);

  return null;
};
