import storageAvailable from "storage-available";
import { LANGUAGE_LOCAL_STORAGE_KEY } from "../../config/storage";
import { isLanguage } from "../isLanguage";

export default function getLanguageFromStorage() {
  if (typeof window === "undefined") {
    return;
  }

  if (!storageAvailable("localStorage")) {
    // eslint-disable-next-line no-console
    console.warn(`localStorage is not available for reading`);
    return;
  }

  const value = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);

  if (!isLanguage(value)) return;

  return value;
}
