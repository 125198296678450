import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { getLanguageFromStorage } from "../helpers/getLanguageFromStorage";
import { setLanguageToStorage } from "../helpers/setLanguageFromStorage";
import { Language } from "../types/Language";
export { Language } from "../types/Language"; // legacy references to this file

const languageFromStorageOrDefault = () =>
  getLanguageFromStorage() ?? Language.NL;

export function LanguageProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useState(languageFromStorageOrDefault);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("useLanguage can only be used within LanguageProvider");
  }

  return {
    ...context,
    setLanguage: (language: Language) => {
      setLanguageToStorage(language);

      context.setLanguage(language);
    },
  };
}

interface LanguageContextInterface {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
}

const LanguageContext = createContext<LanguageContextInterface | undefined>(
  undefined
);
