import { AbsenceStatus } from "../../../types/AbsenceStatus";
import { defaultResponseTransform, emptySplitApi } from "..";

export const absenceApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAbsenceStatus: builder.query<AbsenceStatus, void>({
      query: () => "absence/status",
      transformResponse: defaultResponseTransform,
      providesTags: ["Absence"],
    }),
  }),
});

export const { useGetAbsenceStatusQuery } = absenceApiSlice;
