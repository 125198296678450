import { combineReducers } from "redux";
import { userMetaSlice } from "./userMeta";
import { declarationsApiSlice } from "../apis/declarations";
import { paymentsApiSlice } from "../apis/payments";
import { documentsSlice } from "./documents";
import { sickLeaveSlice } from "./sickLeave";
import { payslipsApiSlice } from "../apis/payslips";
import { vacationHoursApiSlice } from "../apis/vacation-hours";
import { absenceApiSlice } from "../apis/absence";
import { financialApiSlice } from "../apis/financial";

export const rootReducer = combineReducers({
  userMeta: userMetaSlice.reducer,
  declarations: declarationsApiSlice.reducer,
  documents: documentsSlice.reducer,
  sickLeave: sickLeaveSlice.reducer,
  [paymentsApiSlice.reducerPath]: paymentsApiSlice.reducer,
  [payslipsApiSlice.reducerPath]: payslipsApiSlice.reducer,
  [vacationHoursApiSlice.reducerPath]: vacationHoursApiSlice.reducer,
  [absenceApiSlice.reducerPath]: absenceApiSlice.reducer,
  [financialApiSlice.reducerPath]: financialApiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
