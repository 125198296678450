import join from "url-join";
import LRU from "lru-cache";
import { isString } from "../isString";
import { baseUrl } from "./baseUrl";
import addAuthTo from "./add/auth";
import addJsonTo from "./add/json";
import { keysToCamelCase } from "../convert";

const __fetch = require("@vercel/fetch-retry")(fetch);
const _isBrowser = typeof window !== "undefined";
const _isTest = _isBrowser && "Cypress" in window;
const _retryEnabled = !_isTest;

export interface RetryOptions {
  retries?: number;
  factor?: number;
  minTimeout?: number;
  maxTimeout?: number;
  randomize?: boolean;
}

export default async function _fetch(
  input: RequestInfo,
  options?: RequestInit & RetryOptions
): Promise<Response> {
  const request: Request = isString(input)
    ? new Request(join(baseUrl, input), options)
    : input;

  if (!request.headers.has(`Accept`)) {
    addJsonTo(request.headers);
  }

  await addAuthTo(request.headers);

  const retryOptions: RetryOptions = {
    retries: options?.retries ?? 5,
    factor: options?.factor ?? 3,
    minTimeout: options?.minTimeout ?? 1 * 1000,
    maxTimeout: options?.maxTimeout ?? 60 * 1000,
    randomize: options?.randomize ?? true,
  };

  const req: ReturnType<typeof fetch> = _retryEnabled
    ? __fetch(request, { retry: retryOptions })
    : fetch(request);

  return req.then((res) => {
    if (res.status === 401 || res.status === 403) {
      window.location.href = "/inloggen";
    }

    return res;
  });
}

export const cache =
  typeof window !== "undefined"
    ? new LRU({
        maxAge: 1000 * 10,
        stale: true,
      })
    : undefined;

export async function fetchJson<T extends object>(
  input: RequestInfo,
  options?: RequestInit &
    RetryOptions & {
      useCache?: boolean;
      cacheKey?: any;
    }
): Promise<T> {
  const requestMethod =
    typeof input === "string" ? "get" : input.method || options?.method;
  const requestMethodNormal = requestMethod?.toLowerCase();
  const useCache =
    cache &&
    requestMethodNormal === "get" &&
    options?.useCache != null &&
    options?.cacheKey != null;
  const cacheKey = options?.cacheKey;

  if (cache && useCache && cache.has(cacheKey)) {
    return cache.get(cacheKey) as T;
  }

  return _fetch(input, options)
    .then((response) => response.json())
    .then((response) => keysToCamelCase(response))
    .then((json) => {
      if (cache && useCache) {
        cache.set(cacheKey, json);
      }
      return json;
    });
}
